import React, { useEffect } from 'react';
import { MessageContainer } from './RotateMessage.style';
const RotateMessage = () => {

  return (
    <MessageContainer>
      <div className="phone"></div>
      <div className="message">Please rotate your device!</div>
    </MessageContainer>
  );
};

export default RotateMessage;
