import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Warper } from './GlobalWrapper.Styles';
import { useNavigate, Outlet, useParams, useLocation } from 'react-router-dom';
import { Suspense } from 'react';
import Loading from '../../components/Loading/Loading';
import { getUserData } from '../../core/util/getUserData';
const Home = () => {
  const navigate = useNavigate();
  const [userHasAnswered, setUserHasAnswered] = useState([]);
  const [userHasAccess, setUserHasAccess] = useState([]);
  const location = useLocation();

  const checkNotAnswered = (id, route) => {
    if (!userHasAnswered[id]) {
      navigate(route);
    }
  };

  useEffect(() => {
    if (location.pathname.length == 1) {
      navigate(getUserData()?.currentPage);
    }
  }, []);

  return (
    <Warper>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </Warper>
  );
};

export default Home;
