import styled from 'styled-components';

export const Warper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.8);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpinnerWrapper = styled.div`
  /* width: 100%;
  height: 55vh; */
  /* position: absolute; */
  /* background-color: #0000; */
  .text {
    text-transform: capitalize;
  }
`;

export const Content = styled.div`
  display: block;
  /* position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%; */
  perspective: 10000px;
  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    object-position: center;
    transition: transform ease-in-out 1000ms;
    transform-origin: center;
    transform-style: preserve-3d;
  }
  div {
    text-align: center;
    text-transform: uppercase;
    color: var(--primary-color);
    font-family: 'GothamBook';
    margin-top: 10px;
    /* font-size: 18px; */
  }
`;
