import styled from 'styled-components';
import bg from '../../assets/images/login/bg.png';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${bg}) no-repeat;
  background-size: 100% 100%;
  background-color: #2d1673;
  position: relative;
  font-family: 'Lato', sans-serif;

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 2rem 2.25rem;
    border-radius: 10px;

    &__title {
      margin-block: 1rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #120e31;
    }

    &__form-control {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      & label {
        margin-left: 7px;
        color: #5a595c;
      }

      & input {
        width: 400px;
        outline: none;
        border-radius: 50px;
        margin-top: 5px;
        padding: 10px 15px;
        border: none;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.38);

        &::placeholder {
          color: #e4e5e5;
        }
      }
    }

    &__btn {
      margin-top: 2rem;
      border: none;
      background: none;
    }
  }

  .gsk-logo {
    position: absolute;
    width: 70px;
    bottom: 56px;
    left: 56px;
  }
`;
