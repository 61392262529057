import React from 'react';
import { Warper, Content } from './Loading.style';
import loadingImage from '../../assets/images/loading/loading-loading-forever.gif';
import { useSelector } from 'react-redux';
import { getLoadingState } from '../../core/store/loading.slice';

const Loading = () => {
  // const loadingState = useSelector(getLoadingState);
  return (
    <>
      {/* {loadingState && ( */}
      <Warper>
        <Content>
          <img src={loadingImage} alt="loading" />
          {/* <div>Loading...</div> */}
        </Content>
      </Warper>
      {/* )} */}
    </>
  );
};

export default Loading;
