import styled from 'styled-components';

export const MessageContainer = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999;
  background: black;
  height: 100vh;
  width: 100vw;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .phone {
    height: 50px;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    /* display: none; */
  }

  .message {
    color: white;
    font-size: 1em;
    margin-top: 40px;
    /* display: none; */
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(-90deg);
    }
  }

  @media (orientation: portrait) {
    .phone,
    .message {
      display: block;
    }
    display: flex;
  }
`;
