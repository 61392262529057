export const getUserData = () => {
  const userStr = localStorage.getItem('user');
  if (!userStr)
    return {
      currentPage: 'login',
    };
  const user = JSON.parse(userStr);
  // if (user?.room?.name === 'COPD') user['currentPage'] = '/copd';
  // else if (user?.room?.name === 'Severe Asthma') user['currentPage'] = '/severe-asthma';
  // else if (user?.room?.name === 'Asthma') user['currentPage'] = '/Asthma';
  return user;
};
