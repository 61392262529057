import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.loading = true;
    },
    resetLoading: (state, action) => {
      state.loading = false;
    },
  },
});

export const { startLoading, resetLoading } = loadingSlice.actions;

export const getLoadingState = (state) => state.loading.loading;

export default loadingSlice.reducer;
