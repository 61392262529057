import './App.css';
import RotateMessage from './components/RotateMessage/RotateMessage';
import Routes from './core/routes/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { Suspense } from 'react';
import Loading from './components/Loading/Loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Router>
          <RotateMessage />
          <ToastContainer position="top-left" limit={3} />
          {/* <Loading /> */}
          <Routes />
        </Router>
      </Suspense>
    </>
  );
}

export default App;
